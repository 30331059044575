.loader{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.loader .item{
    align-items: center;
    width: 10px;
    height: 40px;
    background-color: #6930C3;
    animation: loader .9s infinite;
    border-radius: 4px;
    margin: 5px;
}

.loader .item:nth-child(1){
    animation-delay: .3s;
}

.loader .item:nth-child(2){
    animation-delay: .6s;
}

@keyframes loader {
    50%{
        height: 15px;
        background-color: #9757ff;
    }
}