.how_it_do{
    display: block;
    padding-bottom: 15vh;
    width: 100%;
}

.howItDoText{
    display: block;
    width: 300px;
    margin: 10vh auto;
    font-size: 30px;
    font-weight: 600;
    box-sizing: border-box;
}

.section{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    justify-content: space-between;
    width: 100%;
}

.sectionItem{
    width: 23vw;
    min-width: 300px;
    min-height: 23vw;
    border-radius: 18px;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    transition: .3s;
    padding-top: 2%;
    padding-bottom: 2%;
    box-sizing: border-box;
    text-align: center;
}

.sectionItem h2{
    font-size: 20px;
    margin-bottom: 0px;
}

.sectionItem h3{
    font-size: 20px;
    color: #7C7C7C;
    font-weight: 500;
    margin: 10px;
    margin-bottom: 13%;
}
.sectionItem img:first-child{
    margin-top: 5%;
}
@media screen and (max-width: 1000px) {
    .howItDoText {
        width: 200px;
        font-size: 20px;
    }

    .section {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        width: 65vw;
        margin: auto;
    }

    .sectionItem {
        width: 65vw;
        min-width: 200px;
        min-height: 65vw;
        padding-top: 7%;
        padding-bottom: 10%;
        margin-bottom: 15%;
        box-sizing: border-box;
        text-align: center;
    }

    .sectionItem h2 {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .sectionItem h3 {
        font-size: 15px;
        color: #7C7C7C;
        font-weight: 500;
        margin: 10px;
        margin-bottom: 13%;
    }

    .sectionItem img {
        width: 30%;
    }
}




















