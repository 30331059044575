*{
    font-family: 'Montserrat', sans-serif;
}

.container{
    margin-left: 12vw;
    margin-right: 12vw;
    width: 76vw;
    max-width: 1200px;
}

@media screen and (max-width: 1034px) {
    .container{
        margin-left: 6vw;
        margin-right: 6vw;
        width: 88vw;
    }
}

@media screen and (max-width: 900px) {
    .container{
        margin-left: 10vw;
        margin-right: 10vw;
        width: 80vw;
    }
}