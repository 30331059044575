.login{
    padding: 12vh 12vh 0;
    font-family: 'Montserrat', sans-serif;
}

.login form{
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    border-radius: 10px;
    padding: 15px 0;
    box-sizing: border-box;
    display: flex;
    width: 45vw;
    margin: auto;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.login h2{
    font-size: 24px;
    font-weight: bold;
    color: #6930C3;
    margin-bottom: 50px;
}

.login label{
    font-size: 24px;
    font-weight: 500;
    color: #6930C3;
}

.login input{
    outline: none;
    background-color: white;
    border-radius: 9px;
    border: 3px solid #6930C3;
    height: 40px;
    font-size: 17px;
    width: 346px;
    padding-left: 10px;
    margin: 30px auto 5px;
}

.login button{
    background-color: #6930C3;
    outline: none;
    color: #80ffdb;
    font-weight: bold;
    font-size: 18px;
    width: 200px;
    height: 60px;
    margin: auto;
    border-radius: 12px;
    cursor: pointer;
    transition: .3s;
}

.login button:hover{
    background-color: #7D4BCC;
}

.login p{
    font-size: 20px;
}

.login a{
    font-size: 15px;
    color: #6930C3;
    margin-bottom: 20px;
}

@media screen and (max-width: 900px) {
    .login{
        padding: 20% 0 0 0;
    }

    .login form{
        padding: 10px 0;
        width: 70vw;
        min-width: 320px;
        box-shadow: none;
        text-align: left;
    }

    .login h2{
        font-size: 20px;
        display: block;
        width: 140px;
        margin: 10px auto;
    }

    .login label{
        font-size: 15px;
        margin-left: 4%;
    }

    .login input{
        height: 30px;
        font-size: 15px;
        width: 90%;
        padding-left: 10px;
        margin-top: 10px;
        margin-bottom: 5px;
        border-radius: 4px;
        border: 2px solid #6930C3;
    }

    .login button{
        font-size: 15px;
        width: 200px;
        height: 45px;
        border: none;
    }

    .login p{
        font-size: 17px;
        display: block;
        width: 125px;
        margin: 20px auto;
    }

    .login a{
        font-size: 17px;
        display: block;
        width: 180px;
        margin: auto;
    }

}

.login small{
    color: red;
    margin-bottom: 30px;
    height: 0px;
    margin-left: 4%;

}

.login .errorClass{
    border: 3px solid red;
}

.login .disabled{
    background-color: #7C7C7C;
    cursor: not-allowed;
    color: black;
}

.login .disabled:hover{
    background-color: #7C7C7C;
    cursor: not-allowed;
}










