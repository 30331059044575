.container{
    margin-left: 12vw;
    margin-right: 12vw;
    width: 76vw;
    max-width: 1200px;
    font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 1034px) {
    .container{
        margin-left: 6vw;
        margin-right: 6vw;
        width: 88vw;
    }
}

.about{
    padding: 15vh 0 ;
}

.about p{
    font-size: 18px;
    font-weight: 400;
}

.aboutVariant{
    display: flex;
    justify-content: space-around;
}

.aboutVariant div{
    text-align: center;
}

.aboutVariant img{
    width: 100px;
}

@media screen and (max-width: 900px) {
    .container{
        margin-left: 10vw;
        margin-right: 10vw;
        width: 80vw;
    }

    .about p{
        font-size: 15px;
        font-weight: 400;
    }

    .aboutVariant img{
        width: 80px;
    }
}





















