.alert{
    margin: 5% 5% 5% 300px;
    text-align: center;
    box-shadow: 0px 0px 5px #7C7C7C;
    padding: 5% 10%;
    border-radius: 10px;
}

.alert h2{
    color: #6930C3;
}

@media screen and (max-width: 900px) {
    .alert{
        margin: 10px;
        text-align: center;
        box-shadow: 0px 0px 5px #7C7C7C;
        padding: 5% 10%;
        border-radius: 10px;
    }

    .alert h2{
        color: #6930C3;
    }
}














